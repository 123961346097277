import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { EnumeratedTypesRepositoryService, JurisdictionInfo, JurisdictionSelectionComponent, Order, OrderManagerService, OrderRepositoryService, OrderTypeJurisdictions, SelectOption, eAssetType, eMatSelectAppearance, eOrderType, eOrderTypeGroup, ePartyType, eQCFormType } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
  selector: 'app-base-order-information',
  templateUrl: './base-order-information.component.html',
  styleUrls: ['./base-order-information.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ]
})
export class BaseOrderInformationComponent implements OnInit, OnDestroy {
  order: Order = { referenceNumber: "",
    country: "CA",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet
  } as Order;

  originalOrderID: string | null = null;
  isLoadingOrder: boolean = false;
  isHistorical: boolean = false;
  
  orderTypeGroupIDs: eOrderTypeGroup[] = [eOrderTypeGroup.PPSARegistration];
  ppsaRegistrationOrderTypeGroup = eOrderTypeGroup.PPSARegistration;
  @ViewChild(JurisdictionSelectionComponent) jurisdictionSelectionComponent!: JurisdictionSelectionComponent;

  protected baseOrderDetails!: FormGroup;
  protected orderTypes: SelectOption[] = [];
  protected orderTypeJurisdictions: OrderTypeJurisdictions[] = [];
  protected jurisdictions: JurisdictionInfo[] = [];
  protected orderTypeFieldName: string = "orderTypeID";
  protected appearance: MatFormFieldAppearance = eMatSelectAppearance.outline;
  protected selectedOrderType: string | null = null;
  protected orderIsQC = false;
  protected qcFormTypes: SelectOption[] = [];
  protected isSaving: boolean = false;
  protected isBasic: boolean = false;

  constructor(
    private environmentUrl: EnvironmentUrlService,
    private formBuilder: FormBuilder,
    private ordersRepo: OrderRepositoryService,
    private orderManager: OrderManagerService,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    private router: Router) {
  }

  ngOnInit(): void {
    const currentURL = this.router.url;
    this.orderTypeGroupIDs = currentURL.includes('new-registration') ? [eOrderTypeGroup.PPSARegistration, eOrderTypeGroup.BasicPPSARegistration] : [eOrderTypeGroup.PPSASearch];
    this.getOrderTypes();
    this.getOrderTypeJurisdictions();
    
    this.baseOrderDetails = this.formBuilder.group({
      referenceNumber: [this.order.referenceNumber, Validators.required],
      country: [this.order.country, Validators.required],
      jurisdiction: [this.order.jurisdiction, Validators.required],
      orderTypeID: [this.order.orderTypeID, Validators.required],
      searchParameters: [{ }]
    })
  }

  ngOnDestroy(): void {
    
  }

  getOrderTypes() {
    this.enumeratedTypesRepo.getOrderTypesAsSelectOptions(this.environmentUrl.urlAddress, this.orderTypeGroupIDs)
      .subscribe(orderTypes => {       
        this.orderTypes = orderTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      })
  }

  getQCFormTypes() {
    var lienFormTypes: eQCFormType[] = [eQCFormType.RDd, eQCFormType.RDe, eQCFormType.RHa, eQCFormType.RDf];
    this.enumeratedTypesRepo.getQcFormTypesAsSelectOptions(this.environmentUrl.urlAddress, lienFormTypes)
      .subscribe(qcFormTypes => {
        this.qcFormTypes = qcFormTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      })
  }

  protected handleJurisdictionChangeEvent() {
    const jurisdiction = this.baseOrderDetails.get('jurisdiction')!.value;
    this.orderIsQC = jurisdiction == "QC";

    if (this.orderIsQC && this.selectedOrderType == "Lien") {
      this.updateQCFormTypeControl();
    }
  }

  protected getOrderTypeJurisdictions() {
    this.enumeratedTypesRepo.getOrderTypeJurisdictions(this.environmentUrl.urlAddress)
        .subscribe(orderTypes => {
          this.orderTypeJurisdictions = orderTypes;
        })
  } 

  protected onOrderTypeSelectionChange() {
    this.selectedOrderType = this.baseOrderDetails.get('orderTypeID')!.value;
    let orderIsLien = (this.selectedOrderType == "Lien");
    this.isBasic = (this.selectedOrderType == "BasicDischarge" || this.selectedOrderType == "BasicRenewal");

    if (this.isBasic) {
      this.isHistorical = false;
    }
    
    // clear jurisdiction control
    this.baseOrderDetails.get('jurisdiction')?.reset();

    this.orderTypeJurisdictions.forEach(orderTypeJurisdiction => {
      if (orderTypeJurisdiction.orderTypeID == this.selectedOrderType) {
        this.jurisdictions = orderTypeJurisdiction.jurisdictions;
      }
    });

    if (this.orderIsQC && orderIsLien) {
      this.updateQCFormTypeControl();
    }
  }

  private updateQCFormTypeControl() {
    if (this.orderIsQC && this.selectedOrderType == "Lien") {
      if (!this.baseOrderDetails.contains('qcFormTypeID')) {
        this.baseOrderDetails.addControl('qcFormTypeID', new FormControl(null, Validators.required));
        this.getQCFormTypes(); // Load QC form types
      }
    } else {
      if (this.baseOrderDetails.contains('qcFormTypeID')) {
        this.baseOrderDetails.removeControl('qcFormTypeID');
      }
    }
  }

  saveContents(): boolean {
    this.isSaving = true;

    // Add extra required groups depending on request type
    if (this.orderTypeGroupIDs[0] == eOrderTypeGroup.PPSARegistration) {
      if (this.orderIsQC && this.selectedOrderType == "Lien") {
        this.baseOrderDetails.addControl('lien', this.formBuilder.group({
          'qcFormTypeID': [ this.baseOrderDetails.get('qcFormTypeID')?.value, Validators.required]
        }));
        this.baseOrderDetails.get('lien')?.get('qcFormTypeID')?.setValue(this.baseOrderDetails.get('qcFormTypeID')?.value);
      } else {
        this.baseOrderDetails.addControl('lien', this.formBuilder.group({}));
      }
      
    } else {
      const orderTypeIDValue = this.baseOrderDetails.get('orderTypeID')!.value;
      if (orderTypeIDValue == "AssetSearch") {
        this.baseOrderDetails.addControl('asset', this.formBuilder.group({
          assetTypeID: [eAssetType.MotorVehicle],
          serialNumber: [""]
        }));
      }
      if (orderTypeIDValue == "DebtorSearch" || orderTypeIDValue == "CertifiedDebtorSearch") {
        this.baseOrderDetails.addControl('debtor', this.formBuilder.group({
          partyTypeID: [ePartyType.individualDebtor],
          busName: [""]
        }));
      }
    }

    if (!this.baseOrderDetails.valid) {
      this.baseOrderDetails.markAllAsTouched();
      this.baseOrderDetails.updateValueAndValidity();

      return false;
    }

    this.ordersRepo.createNewOrder(this.baseOrderDetails.value, this.isHistorical)
    .subscribe(result => {
      this.isSaving = false;
      const order = (result as any).resource;
      this.order = order;
      this.orderManager.updateOrder(order);
      if (this.order.id) {
        this.router.navigate([
          `${this.orderTypeGroupIDs[0] == eOrderTypeGroup.PPSARegistration
            ? 'new-registration'
            : 'new-search'}/${order.id}`
        ]);
      }
    })

    // do validation here
    return true;
  }

  // TODO: Fill out validation
  validateContents(form?: NgForm): StepValidationState {
    return StepValidationState.ValidationSuccess;
  }
}
