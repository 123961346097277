<div class="padding">
    <h1 class="left-box">Reports</h1>

    <!-- Search Bar -->
    <div class="flex-container">
        <mat-form-field appearance="outline" class="form-field-full">
        <mat-label>Search Reports</mat-label>
        <input
            matInput
            (keyup)="filterReports($event)"
            placeholder="Type to search"
        />
        </mat-form-field>
    </div>

    <div class="flex-container flex-wrap">
        <div class="document-card">
            <mat-card class="mat-elevation-z5">
                <!-- Thumbnail Image -->
                <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">alarm</mat-icon>

                <!-- Card Content -->
                <mat-card-content class="padding">
                    <mat-card-title class="card-title">Active Registrations Report</mat-card-title>
                    <mat-card-subtitle>All Active Registrations that have not expired or been
                        discharged.</mat-card-subtitle>
                </mat-card-content>

                <!-- Card Actions -->
                <mat-card-actions class="card-actions">
                    <span class="spacer"></span>
                    <button mat-raised-button color="primary" class="prominent-button" [routerLink]="'actives'">
                        Request
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="document-card">
            <mat-card class="mat-elevation-z5">
                <!-- Thumbnail Image -->
                <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">alarm</mat-icon>

                <!-- Card Content -->
                <mat-card-content class="padding">
                    <mat-card-title class="card-title">Expiring Registrations Report</mat-card-title>
                    <mat-card-subtitle>All Registrations Expiring within a given timeframe.</mat-card-subtitle>
                </mat-card-content>

                <!-- Card Actions -->
                <mat-card-actions class="card-actions">
                    <span class="spacer"></span>
                    <button mat-raised-button color="primary" class="prominent-button" [routerLink]="'expiring'">
                        Request
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="document-card" *ngFor="let report of filteredReports" [@fadeIn]>
            <mat-card class="mat-elevation-z5">
                <!-- Thumbnail Image -->
                <mat-icon class="icon-display padding-top-bottom-25 padding-sides-25">summarize</mat-icon>

                <!-- Card Content -->
                <mat-card-content class="padding">
                    <mat-card-title class="card-title">{{ report.name }}</mat-card-title>
                    <mat-card-subtitle>{{ report.description }}</mat-card-subtitle>
                </mat-card-content>

                <!-- Card Actions -->
                <mat-card-actions class="card-actions">
                    <span class="spacer"></span>
                    <button mat-raised-button color="primary" class="prominent-button" [routerLink]="['reportTemplates',report.id]">
                        Request
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>