import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { trigger, transition, style, animate } from '@angular/animations';
import { ConfigurationState, ReportTemplate, ReportTemplatesRepositoryService } from 'reg-hub-common';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms ease-in', style({ opacity: 1 })),
            ]),
        ]),
    ],
})
export class ReportsComponent implements OnInit {
    allReports: ReportTemplate[] = [];
    filteredReports: ReportTemplate[] = [];

    constructor(
        private repo: ReportTemplatesRepositoryService,
        private configurationState: ConfigurationState,
        private messageService: ToastrService
    ) { }

    ngOnInit(): void {
        let params = new HttpParams()
            .set('sortColumn', 'Name');

        this.configurationState.getCorporationID().then(corporationID => {

            this.repo.populateBaseLink([corporationID]);

            this.repo.getFirstPageSubscribable(params).subscribe((results) => {
                this.allReports = results;
                this.filteredReports = results;
            });
        });
    }

    filterReports(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        const searchTerm = inputElement.value ?? '';

        if (!searchTerm) {
            this.filteredReports = this.allReports;
        } else {
            const lowerSearchTerm = searchTerm.toLowerCase();
            this.filteredReports = this.allReports.filter((report) =>
                report.name?.toLowerCase().includes(lowerSearchTerm) ||
                (report.description && report.description.toLowerCase().includes(lowerSearchTerm))
            );
        }
    }
}