import { Component, EventEmitter, Input, ViewChild, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { SteppedComponent } from '../../../interfaces/stepped-component';
import { eDischargeType, EnumeratedTypesRepositoryService, eOrderType, eOrderTypeGroup, eQCFormType, SelectOption, ValidationService } from 'reg-hub-common';
import { Order, OrderManagerService, ValidationProblem } from 'reg-hub-common';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';
import { TermComponent } from '../term/term.component';

@Component({
  selector: 'app-basic-lien-details',
  templateUrl: './basic-lien-details.component.html',
  styleUrls: ['./basic-lien-details.component.css']
})
export class BasicLienDetailsComponent extends SteppedComponent {
  @ViewChild(TermComponent) termComponent!: TermComponent;
  @Input() isDisabled: boolean = false;
  @Output() termFormValueChangedEvent = new EventEmitter<any>();

  lienDetails!: FormGroup;
  protected qcFormTypes: SelectOption[] = [];
  protected orderTypes: SelectOption[] = [];
  protected totalDischarge: eDischargeType = eDischargeType.totalDischarge;
  protected agreementToDischarge: eDischargeType = eDischargeType.agreementToDischarge;

  protected registrationNumberError$ = new BehaviorSubject<string | null | undefined>(null);
  protected fileNumberError$ = new BehaviorSubject<string | null | undefined>(null);
  protected rinError$ = new BehaviorSubject<string | null | undefined>(null);
  protected originalSigningDateError$ = new BehaviorSubject<string | null | undefined>(null);
  protected currentExpiryDateError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(private formBuilder: FormBuilder,
    private environmentUrl: EnvironmentUrlService,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.lienDetails = this.formBuilder.group({ }) as FormGroup;

    if (this.uiConfiguration.referenceRegistrationInformationUIConfiguration?.showRegistrationNumber) {
      this.lienDetails.addControl('registrationNumber', this.formBuilder.control(this.order.referenceRegistrationInformation?.originalRegistrationNumber))
    }

    if (this.uiConfiguration.referenceRegistrationInformationUIConfiguration?.showRIN) {
      this.lienDetails.addControl('rin', this.formBuilder.control(this.order.referenceRegistrationInformation?.rin))
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showFileNumber ||
      this.uiConfiguration.referenceRegistrationInformationUIConfiguration?.showFileNumber) {
      this.lienDetails.addControl('fileNumber', this.formBuilder.control(this.order.lien?.fileNumber))
    }

    if (this.uiConfiguration.referenceRegistrationInformationUIConfiguration?.showOriginalSigningDate) {
      this.lienDetails.addControl('originalSigningDate', this.formBuilder.control(this.order.referenceRegistrationInformation?.originalSigningDate))
    }

    if (this.uiConfiguration.referenceRegistrationInformationUIConfiguration?.showOriginalQCFormTypeID) {
      this.lienDetails.addControl('originalQCFormTypeID', this.formBuilder.control(this.order.referenceRegistrationInformation?.originalQCFormTypeID, Validators.required));
      this.getQCFormTypes(); // Load QC form types
    }

    if (this.uiConfiguration.referenceRegistrationInformationUIConfiguration?.showOriginalOrderTypeID) {
      this.lienDetails.addControl('originalOrderTypeID', this.formBuilder.control(this.order.referenceRegistrationInformation?.originalOrderTypeID, Validators.required));
      this.getOrderTypes();
    }

    if (this.uiConfiguration.lienDetailsConfiguration?.showTerm) {
      this.lienDetails.addControl('term', this.formBuilder.control(this.order.lien?.term))
    }

    if (this.uiConfiguration.referenceRegistrationInformationUIConfiguration?.showExpiryDate) {
      this.lienDetails.addControl('currentExpiryDate', this.formBuilder.control(this.order.referenceRegistrationInformation?.expiryDate))
    }

    if (this.uiConfiguration.dischargeDetailsConfiguration?.showDischargeType) {
      this.lienDetails.addControl('dischargeType', this.formBuilder.control(this.order?.discharge?.dischargeType ?? this.totalDischarge));
    }

    this.observableErrors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
    this.lienDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        try {
          let orderToValidate = this.getOrderToValidate();
          this.validate(orderToValidate);
        } catch {

        }
      });

    if (this.isDisabled) {
      this.lienDetails.disable();
    }
  }

  override ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    if (this.termComponent) {
      if (this.uiConfiguration.lienDetailsConfiguration?.showInfinityTerm) {
        this.lienDetails.setControl('isInfiniteTerm', this.termComponent.termDetails.get('isInfiniteTerm'));
      }

      if (this.uiConfiguration.lienDetailsConfiguration?.showTerm) {
        this.lienDetails.setControl('term', this.termComponent.termDetails.get('term'));
      }

      if (this.uiConfiguration.lienDetailsConfiguration?.showExpiryDate) {
        this.lienDetails.setControl('expiryDate', this.termComponent.termDetails.get('expiryDate'));
      }
    }
  }
  
  getQCFormTypes() {
    var lienFormTypes: eQCFormType[] = [eQCFormType.RDd, eQCFormType.RDe, eQCFormType.RHa, eQCFormType.RDf];
    this.enumeratedTypesRepo.getQcFormTypesAsSelectOptions(this.environmentUrl.urlAddress, lienFormTypes)
      .subscribe(qcFormTypes => {
        this.qcFormTypes = qcFormTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      })
  }

  getOrderTypes() {
    this.enumeratedTypesRepo.getOrderTypesAsSelectOptions(this.environmentUrl.urlAddress, [eOrderTypeGroup.PPSARegistration])
    .subscribe(orderTypes => {
      this.orderTypes = orderTypes.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
    })
  }

  onTermSelectionChange(event: MatRadioChange): void {
    if (event.value === true) {
      this.order.lien!.expiryDate = null;
      this.order.lien!.term = null;
      this.order.lien!.isInfiniteTerm = true;
    } else {
      this.order.lien!.isInfiniteTerm = false;
    }
  }

  override onSaving(): void {
    this.mapFormToOrder(this.order);
    this.orderManager.updateOrder(this.order);
  }

  private mapFormToOrder(order: Order) {
    if(order.referenceRegistrationInformation) {
      order.referenceRegistrationInformation.originalRegistrationNumber = this.lienDetails.get('registrationNumber')?.value;
      order.referenceRegistrationInformation.rin = this.lienDetails.get('rin')?.value;
      order.referenceRegistrationInformation.originalQCFormTypeID = this.lienDetails.get('originalQCFormTypeID')?.value;
      order.referenceRegistrationInformation.originalSigningDate = this.lienDetails.get('originalSigningDate')?.value;
      order.referenceRegistrationInformation.expiryDate = this.lienDetails.get('currentExpiryDate')?.value;
      order.referenceRegistrationInformation.originalOrderTypeID = this.lienDetails.get('originalOrderTypeID')?.value;
    }

    if(order.lien) {
      order.lien.fileNumber = this.lienDetails.get('fileNumber')?.value;
      order.lien.term = this.lienDetails.get('term')?.value;
      order.lien.expiryDate = this.lienDetails.get('expiryDate')?.value;
      order.lien!.dischargeType = this.lienDetails.get('dischargeType')?.value;
    }
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    if (!errors) {
      return;
    }

    if(this.termComponent) {
      this.termComponent.pushErrors(errors);
    }

    this.registrationNumberError$.next(errors?.filter(error => error.path.includes('/originalregistrationnumber'))?.at(0)?.userFriendlyMessage);
    this.fileNumberError$.next(errors?.filter(error => error.path.includes('/filenumber'))?.at(0)?.userFriendlyMessage);
    this.rinError$.next(errors?.filter(error => error.path.includes('/rin'))?.at(0)?.userFriendlyMessage);
    this.currentExpiryDateError$.next(errors?.filter(error => error.path.includes('referenceregistrationinformation/expirydate'))?.at(0)?.userFriendlyMessage);
    this.originalSigningDateError$.next(errors?.filter(error => error.path.includes('/originalsigningdate'))?.at(0)?.userFriendlyMessage);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    const filteredErrors = errors?.filter(error =>
      error.path.includes('lien'));
    return filteredErrors?.length ?? 0 > 0 ? StepValidationState.ValidationError : StepValidationState.ValidationSuccess;
  }

  protected getOrderToValidate(): Order {
    let orderToValidate = this.copyOrder(this.order);
    this.mapFormToOrder(orderToValidate);

    return orderToValidate;
  }
}